// - -------------------------------------------------------------------- - //

import { prettyPrice } from 'd2/utils/StringHelpers'
import $ from 'jquery'
import Radioer from './Radioer.js'
import Validation from '../utils/Validation.js'

let braintree

if (window.VYDIA_SETTINGS.ENVIRONMENT !== 'test') {
  braintree = require('braintree-web-d1')
}

// - -------------------------------------------------------------------- - //

function PaymentForm () {
  this.ev = $({})
}

PaymentForm.prototype = {

  on (event, callback) {
    this.ev.on(event, callback)
  },

  off (event, callback) {
    this.ev.off(event, callback)
  },

  setup () {
    this.setupForm()
    this.setupMethod()
    this.setupBalance()
    this.setupCC()
    this.setupTOS()

    // MUST EXECUTE LAST;
    this.setupBraintree()
  },

  setupForm () {
    const _this = this

    this.formSubmitting = false

    this.$confirmationForm = $('#confirmation-form')
    this.$submitButton = this.$confirmationForm.find('input[type=\'submit\']')

    this.$confirmationForm.on(
      'change',
      '#confirmation_existing_paypal_account_id, #confirmation_existing_credit_card_id',
      () => {
        _this.toggleCreatePaymentMethodForm()
      }
    )

    // stop enter key from cancelling PayPal
    this.$confirmationForm.keypress((event) => {
      const charCode = event.charCode || event.keyCode
      if (charCode === 13) { // Enter key's keycode
        event.preventDefault()
        event.stopPropagation()
        return false
      }
    })

    // validate client-side
    this.$submitButton.on('click', (event) => {
      if (_this.formSubmitting) {
        event.preventDefault()
        return false
      }

      const errorMessage = _this.validateConfirmationForm()
      if (errorMessage) {
        _this.ev.trigger('error', [errorMessage])
        event.preventDefault()
        alert(errorMessage)
        return false
      }
      _this.ev.trigger('submit')

      _this.formSubmitting = true
    })
  },

  setupMethod () {
    const _this = this

    this.$newPaymentMethodForm = $('#new-payment-method')
    this.$existingPaymentMethodsForm = $('#existing-payment-methods')
    this.$existingCreditCardCVVForm = $('#existing-credit-card-cvv-form')
    this.$existingPaypalAddressForm = $('#existing-paypal-address-form')
    this.$hiddenExceptForPaymentType = $('.paypal_account_visible, .credit_card_visible')

    this.paymentMethodsRadioer = new Radioer({
      inputName: 'confirmation[paypal]',
      activeClass: 'active',
      containerSelector: '.radio-option',
    })

    this.paymentMethodsRadioer.on('change', () => {
      _this.toggleSpecificPaymentMethodForm()
    })
  },

  setupBalance () {
    const _this = this

    this.$useBalanceContainer = $('#use-balance-options')
    this.$useBalance = $('#confirmation_use_balance')
    this.$dontUseBalance = $('#confirmation_dont_use_balance')

    this.potentialRemainingBraintreeAmount = parseFloat($('#confirmation_potential_remaining_braintree_amount').val())

    this.useBalanceRadioer = new Radioer({
      inputName: 'confirmation[use_balance]',
      activeClass: 'active',
      containerSelector: '.radio-option',
    })

    this.useBalanceRadioer.on('change', () => {
      _this.toggleBraintreeContainer()
    })
  },

  setupTOS () {
    const _this = this

    this.$tosCheckbox = $('#confirmation_accept_tos')
    this.$confirmationTOS = $('#confirmation-tos')
    this.$copyrightCheckbox = $('#confirmation_accept_copyright')
    this.$systemCheckbox = $('#confirmation_accept_system')
    this.$legalName = $('#confirmation_legal_name')

    this.$tosCheckbox.on('change', () => {
      _this.handleTOS()
    })

    this.$copyrightCheckbox.on('change', () => {
      _this.handleTOS()
    })

    this.$systemCheckbox.on('change', () => {
      _this.handleTOS()
    })

    this.$legalName.on('keyup', () => {
      _this.handleTOS()
    })

    this.handleTOS()
  },

  setupCC () {
    const _this = this

    this.$hidden_cc_number = $('#hidden_cc_number')
    this.$hidden_expiration_month = $('#hidden_expiration_month')
    this.$hidden_expiration_year = $('#hidden_expiration_year')
    this.$hidden_country_code = $('#hidden_country_code')
    this.$hidden_postal_code = $('#hidden_postal_code')
    this.$hidden_street_address = $('#hidden_street_address')
    this.$hidden_extended_address = $('#hidden_extended_address')
    this.$hidden_locality = $('#hidden_locality')
    this.$hidden_region = $('#hidden_region')
    this.$hidden_existing_credit_card_cvv = $('#hidden_existing_credit_card_cvv')

    this.$confirmation_cc_number = $('#confirmation_cc_number')
    this.$confirmation_expiration_month = $('#confirmation_expiration_month')
    this.$confirmation_expiration_year = $('#confirmation_expiration_year')
    this.$confirmation_country_code = $('.country-code .form-input')
    this.$confirmation_postal_code = $('.postal-code .form-input')
    this.$confirmation_street_address = $('.street-address .form-input')
    this.$confirmation_extended_address = $('.extended-address .form-input')
    this.$confirmation_locality = $('.city .form-input')
    this.$confirmation_state = $('.state .form-input')
    this.$confirmation_region = $('.province .form-input')
    this.$cc_confirmation_region = $('#cc_confirmation_region')
    this.$confirmation_existing_credit_card_cvv = $('#confirmation_existing_credit_card_cvv')

    this.$confirmation_cc_number.on('change', function () {
      _this.$hidden_cc_number.val(this.value)
    })

    this.$confirmation_expiration_month.on('change', function () {
      _this.$hidden_expiration_month.val(this.value)
    })

    this.$confirmation_expiration_year.on('change', function () {
      _this.$hidden_expiration_year.val(this.value)
    })

    this.$confirmation_country_code.on('change', function () {
      _this.$hidden_country_code.val(this.value)
    })

    this.$confirmation_postal_code.on('change', function () {
      _this.$hidden_postal_code.val(this.value)
    })

    this.$confirmation_street_address.on('change', function () {
      _this.$hidden_street_address.val(this.value)
    })

    this.$confirmation_extended_address.on('change', function () {
      _this.$hidden_extended_address.val(this.value)
    })

    this.$confirmation_locality.on('change', function () {
      _this.$hidden_locality.val(this.value)
    })

    this.$confirmation_state.on('change', function () {
      _this.$hidden_region.val(this.value)
      _this.$confirmation_region.val(this.value)
      _this.$cc_confirmation_region.val(this.value)
      _this.$confirmation_state.val(this.value)
    })

    this.$confirmation_region.on('change', function () {
      _this.$hidden_region.val(this.value)
    })

    this.$confirmation_existing_credit_card_cvv.on('change', function () {
      _this.$hidden_existing_credit_card_cvv.val(this.value)
    })
  },

  setupBraintree () {
    const _this = this
    const formId = this.$confirmationForm.attr('id')
    const notDev = window.VYDIA_SETTINGS.ENVIRONMENT !== 'test' && window.VYDIA_SETTINGS.ENVIRONMENT !== 'development'
    const notStaging = window.VYDIA_SETTINGS.ENVIRONMENT !== 'staging'
    const environment = notDev && notStaging ? 'production' : 'sandbox'

    this.braintreeClientToken = window.braintreeClientToken
    this.newPaypalAccountAuthorized = false

    this.$paypalButton = $('#paypal-button')
    this.$paypalInstructions = $('#paypal-instructions')
    this.$braintreeFormContainer = $('#braintree-form-container')

    if (this.$newPaymentMethodForm.length) {
      braintree.setup(this.braintreeClientToken, 'custom', {
        // DataCollector Options for Kount fraud protection
        dataCollector: {
          kount: {
            environment,
          },
        },
        onReady (braintreeInstance) {
          // onReady fires when payment form is available
          // we add a hidden field that keeps device data
          // the device data is sent to braintree for kount
          const form = document.getElementById(formId)
          let deviceDataInput = form['device_data']
          if (deviceDataInput == null) {
            deviceDataInput = document.createElement('input')
            deviceDataInput.name = 'device_data'
            deviceDataInput.type = 'hidden'
            form.appendChild(deviceDataInput)
          }
          deviceDataInput.value = braintreeInstance.deviceData
        },
        id: formId,
        paypal: {
          container: this.$paypalButton,
          displayName: 'Vydia, Inc.',
          onSuccess () {
            _this.authorizePayPal()
          },
          onUnsupported () {
            alert('Sorry, Vydia does not support the browser you\'re using.\n'
                  + 'If you want to checkout with PayPal, upgrade to a modern browser '
                  + 'like Google Chrome. We\'ll be waiting!')
          },
          onCancelled () {
            _this.unauthorizePayPal()
          },
        },
      })
    }
  },

  openFirstPanel () {
    this.toggleUseBalanceContainer(0)
    this.toggleBraintreeContainer(0)
    this.toggleSpecificPaymentMethodForm(0)
    this.toggleCreatePaymentMethodForm(0)
  },

  // Helper
  slideDown ($el, duration, callback) {
    return $el.stop(true, false).slideDown(duration, () => {
      $el.css('height', 'auto')
      callback && callback()
    })
  },

  slideUp ($el, duration, callback) {
    return $el.stop(true, false).slideUp(duration, () => {
      $el.css('height', 'auto')
      callback && callback()
    })
  },

  // Query
  isAnyPaymentRequired () {
    return this.$useBalanceContainer.length !== 0
        || this.$braintreeFormContainer.length !== 0
  },

  isPendingUseBalance () {
    return this.$useBalanceContainer.length !== 0
        && !this.useBalanceRadioer.anyChecked()
  },

  isPendingPaymentMethod () {
    return this.isBraintreeContainerVisible()
        && this.isExistingPaymentMethodSelectEmpty()
  },

  isPendingAny () {
    return this.isPendingUseBalance()
        || this.isPendingPaymentMethod()
  },

  isExistingPaymentMethodSelectEmpty () {
    const value = this.$getPaymentMethodSelect().val()
    return value === undefined || value === ''
  },

  isExistingPaymentMethodSelected () {
    const value = this.$getPaymentMethodSelect().val()
    return value && value !== '0'
  },

  isExistingPaymentMethodSelectSetToNew () {
    const value = this.$getPaymentMethodSelect().val()
    return value === '0'
  },

  isTOSVisible () {
    return !this.isPendingAny()
        || this.isCreatePaymentMethodFormVisible()
  },

  isUsingBalance () {
    const value = this.useBalanceRadioer.val()
    return value && value.toString() !== ''
  },

  isUsingFullBalance () {
    return this.isUsingBalance()
        && this.$useBalance.data('full-balance')
  },

  isPaymentMethodTypeSelected () {
    return !this.isUsingFullBalance()
        && this.paymentMethodsRadioer.anyChecked()
  },

  isPaymentMethodTypeSelectedAndPayPal () {
    return this.isPaymentMethodTypeSelected()
        && this.paymentMethodsRadioer.val().toString() !== ''
  },

  isPaymentMethodTypeSelectedAndCreditCard () {
    return this.isPaymentMethodTypeSelected()
        && this.paymentMethodsRadioer.val().toString() === ''
  },

  doesUserHaveExistingPaymentMethod (paymentMethodName) {
    paymentMethodName = this.getPaymentMethodName(paymentMethodName)
    return this.$getPaymentMethodSelect(paymentMethodName).length > 0
  },

  doesExistingPaymentMethodHaveAddress () {
    const value = this.$getPaymentMethodSelect().val()
    const addressValue = this.$getPaymentMethodSelect()
      .find(`option[value='${value}']`)
      .data('address')
    return addressValue && addressValue !== '0'
  },

  isCreatePaymentMethodFormVisible (paymentMethodName) {
    let isCreatePaymentMethodFormVisible = false
    if (this.isPaymentMethodTypeSelected()) {
      paymentMethodName = this.getPaymentMethodName(paymentMethodName)
      const $select = this.$getPaymentMethodSelect(paymentMethodName)
      const val = $select.val()
      isCreatePaymentMethodFormVisible = $select.length === 0 || (val && val.toString() === '0')
    }
    return isCreatePaymentMethodFormVisible
  },

  isBraintreeContainerVisible () {
    return this.$braintreeFormContainer.length !== 0
        && !this.isPendingUseBalance()
        && !this.isUsingFullBalance()
  },

  getPaymentMethodName (paymentMethodName) {
    if (!paymentMethodName) {
      paymentMethodName = this.isPaymentMethodTypeSelectedAndPayPal()
        ? 'paypal_account'
        : 'credit_card'
    }
    return paymentMethodName
  },

  $getPaymentMethodSelect (paymentMethodName) {
    paymentMethodName = this.getPaymentMethodName(paymentMethodName)
    return this.$confirmationForm.find(`#confirmation_existing_${paymentMethodName}_id`)
  },

  toggleRemainingBraintreeAmount () {
    const $container = this.$braintreeFormContainer.find('.secondary-header h1 .remaining-amount')
    const isUsingBalance = this.isUsingBalance()

    if (isUsingBalance) {
      // using full balance, but must setup subscription payment method
      if (parseFloat(this.potentialRemainingBraintreeAmount) === 0.0) {
        $container.html('for the yearly subscription')
      } else {
        // using balance, but not full amount
        const prettyAmount = prettyPrice(this.potentialRemainingBraintreeAmount)
        $container.html(`for the remaining ${prettyAmount}`)
      }
    }

    $container.stop(true, false)
    if (isUsingBalance) {
      $container.fadeIn()
    } else {
      $container.fadeOut()
    }
  },

  toggleTOSVisibility (duration) {
    if (this.isTOSVisible()) {
      this.slideDown(this.$confirmationTOS, duration)
    } else {
      this.slideUp(this.$confirmationTOS, duration)
    }
  },

  toggleUseBalanceContainer (duration) {
    if (this.$useBalanceContainer.length !== 0) {
      this.$useBalanceContainer.slideDown(duration)
    }
  },

  toggleBraintreeContainer (duration) {
    if (this.isBraintreeContainerVisible()) {
      this.toggleRemainingBraintreeAmount()
      this.slideDown(this.$braintreeFormContainer, duration)
    } else {
      this.slideUp(this.$braintreeFormContainer, duration)
    }
    this.toggleTOSVisibility(duration)
  },

  showRelevantPaymentMethodForm (duration) {
    const paymentMethodName = this.getPaymentMethodName()
    const $hide = this.$hiddenExceptForPaymentType.not(`.${paymentMethodName}_visible`)
    const $show = this.$hiddenExceptForPaymentType.filter(`.${paymentMethodName}_visible`)
    if (parseInt(duration) === 0) {
      $hide.hide()
      $show.show()
    } else {
      this.slideUp($hide, duration, () => {
        this.slideDown($show, duration)
      })
    }
  },

  toggleSpecificPaymentMethodForm (duration) {
    if (this.isPaymentMethodTypeSelected()) {
      this.toggleCreatePaymentMethodForm(duration)
      this.showRelevantPaymentMethodForm(duration)
      this.slideDown(this.$existingPaymentMethodsForm, duration)
    } else {
      this.slideUp(this.$existingPaymentMethodsForm, duration)
    }
    this.toggleTOSVisibility(duration)
  },

  toggleCreatePaymentMethodForm (duration) {
    if (this.isCreatePaymentMethodFormVisible()) {
      this.slideDown(this.$newPaymentMethodForm, duration)
      this.slideUp(this.$existingCreditCardCVVForm, duration)
      this.slideUp(this.$existingPaypalAddressForm, duration)
    } else {
      this.slideUp(this.$newPaymentMethodForm, duration)
      if (this.isExistingPaymentMethodSelected()) {
        this.slideDown(this.$existingCreditCardCVVForm, duration)
        if (this.doesExistingPaymentMethodHaveAddress()) {
          this.slideUp(this.$existingPaypalAddressForm, duration)
        } else if (this.getPaymentMethodName() === 'paypal_account') {
          this.slideDown(this.$existingPaypalAddressForm, duration)
        } else {
          this.slideUp(this.$existingPaypalAddressForm, duration)
        }
      } else {
        this.slideUp(this.$existingCreditCardCVVForm, duration)
        this.slideUp(this.$existingPaypalAddressForm, duration)
      }
    }
    this.toggleTOSVisibility(duration)
  },

  validateConfirmationForm () {
    const _this = this
    let msg

    if (this.isAnyPaymentRequired()) {
      if (this.isPaymentMethodTypeSelectedAndCreditCard()) {
        let cardRules

        if (!this.doesUserHaveExistingPaymentMethod('credit_card') || this.isExistingPaymentMethodSelectSetToNew()) {
          cardRules = {
            'confirmation_first_name': { min: 1, max: 256 },
            'confirmation_last_name': { min: 1, max: 256 },
            'confirmation_cc_number': { min: 15, max: 19 },
            'confirmation_expiration_month': 2,
            'confirmation_expiration_year': 4,
            'confirmation_cvv': { min: 3, max: 4 },
            'cc_confirmation_street_address': { min: 1, max: 256 },
            'cc_confirmation_locality': { min: 1, max: 256 },
          }
          if (this.$hidden_country_code.val() === 'US') {
            cardRules['cc_confirmation_region'] = { min: 1, max: 256 }
          }
        } else if (this.isExistingPaymentMethodSelected()) {
          cardRules = {
            'confirmation_existing_credit_card_cvv': { min: 3, max: 4 },
          }
        } else if (this.isExistingPaymentMethodSelectEmpty()) {
          msg = 'Oops! Please select an option from the Credit Card drop-down to authorize this purchase.'
        }

        if (cardRules) {
          $.each(cardRules, (field, rules) => {
            const $field = _this.$confirmationForm.find(`#${field}`)
            if (Validation.validateLength($field, rules)) {
              msg = 'Oops! Please verify your Credit Card information and try again.'
              return false
            }
          })
        }
      } else if (this.isPaymentMethodTypeSelectedAndPayPal()) {
        if (this.doesUserHaveExistingPaymentMethod('paypal_account')) {
          if (this.isExistingPaymentMethodSelectEmpty()) {
            msg = 'Oops! Please select an option from the PayPal drop-down to authorize this purchase.'
          } else if (this.isExistingPaymentMethodSelectSetToNew() && !this.newPaypalAccountAuthorized) {
            msg = 'Oops! Please click the PayPal button to authorize this purchase.'
          }
        } else if (!this.newPaypalAccountAuthorized) {
          msg = 'Oops! Please click the PayPal button to authorize this purchase.'
        }
      } else if (!this.isUsingFullBalance() && !this.isPaymentMethodTypeSelected()) {
        msg = 'Oops! Please select a payment method before confirming.'
      }
    } // .. payment required

    if (!window.claAlreadySigned && !this.$tosCheckbox.prop('checked')) {
      msg = 'Oops! Please read and accept the terms before confirming.'
    }

    if (!window.claAlreadySigned && this.$submitButton.hasClass('disabled')) {
      msg = 'Oops! It looks like the form is missing something.\nPlease verify and try again.'
    }

    return msg
  },

  handleTOS () {
    let checked = this.$tosCheckbox.prop('checked')
    if (this.$legalName.length > 0) {
      checked = checked && this.$legalName.val().length > 0
    }
    if (this.$copyrightCheckbox.length) {
      checked = checked && this.$copyrightCheckbox.prop('checked') && this.$systemCheckbox.prop('checked')
    }
    if (window.claAlreadySigned) {
      checked = true
    }
    this.$submitButton.toggleClass('disabled', !checked)
  },

  authorizePayPal () {
    this.newPaypalAccountAuthorized = true
    this.$paypalInstructions.html('PayPal Authorized')
  },

  unauthorizePayPal () {
    this.newPaypalAccountAuthorized = false
    this.$paypalInstructions.html('Click the PayPal button to authorize the purchase')
  },

}

// - -------------------------------------------------------------------- - //

export default PaymentForm

// - -------------------------------------------------------------------- - //
