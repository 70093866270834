import isTestEnv from 'd2/utils/isTestEnv'
const isDevEnv = window.settingsEnv === 'development'

// TODO: Use RAYGUN bool from vydia settings instead.
if (!isTestEnv && !isDevEnv) {
  const cdnUrl = window.VYDIA_SETTINGS.CDN_URL
  const stripProtocol = function (url) {
    return url.replace(/https?:\/\//, '')
  }

  if (window.rg4js) {
    window.rg4js('options', {
      debugMode: false,
      ignore3rdPartyErrors: true,
      ignoreAjaxError: true,
    })

    if (cdnUrl) {
      window.rg4js('whitelistCrossOriginDomains', [stripProtocol(cdnUrl)])
    }

    window.rg4js('setVersion', window.VYDIA_VERSION)
    window.rg4js('setUser', {
      identifier: window.current_user.id,
      isAnonymous: false,
      email: window.current_user.email,
      firstName: window.current_user.first_name,
      fullName: `${window.current_user.first_name} ${window.current_user.last_name}`,
    })
    window.rg4js('withTags', ['js'])

    window.rg4js('apiKey', window.VYDIA_SETTINGS.RAYGUN_API_KEY)
    window.rg4js('enableCrashReporting', true)
    window.rg4js('enablePulse', true)
    window.rg4js('noConflict', true)
  }
}
