import $ from 'jquery'
import ready from '../utils/ready'

ready(() => {
  const $modalWrapper = $('#modal-wrapper')
  const $youtubeVideoModal = $('.youtube-video-modal')

  if ($youtubeVideoModal.length > 0) {
    $youtubeVideoModal.each(function () {
      const $thisModal = $(this)

      $modalWrapper.on('click', () => {
        $thisModal.html($thisModal.html())
      })
    })
  }
})
