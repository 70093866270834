import $ from 'jquery'
import Filterable from '../components/Filterable'
import ready from '../utils/ready'

ready(() => {
  const $newsListWidget = $('.news-list-widget')
  if ($newsListWidget.length > 0) {
    /* eslint-disable no-new */
    new Filterable({
      '$filterables': $newsListWidget.find('.news-list'),
      '$tabsContainer': $newsListWidget.find('.widget-tabs'),
      moveToTop: true,
    })
    /* eslint-enable no-new */
  }
})
