import $ from 'jquery'
import PaymentForm from '../components/PaymentForm.js'
import ready from '../utils/ready'

ready(() => {
  const confirmationFormID = 'confirmation-form'
  const $confirmationForm = $(`#${confirmationFormID}`)

  const paymentForm = new PaymentForm()

  $('.province').hide()

  $('.country-code').change(() => {
    if ($('#hidden_country_code').val() !== 'US') {
      $('.state').hide()
      $('#confirmation_region').val('')
      $('.province').show()
    } else {
      $('.state').show()
      $('.province').hide()
      $('#confirmation_region').val('')
    }
  })

  if ($confirmationForm.length > 0 && window.VYDIA_SETTINGS.ENVIRONMENT !== 'test') {
    paymentForm.setup()
    paymentForm.openFirstPanel()
  }
})
