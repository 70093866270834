import $ from 'jquery'

// @param options
// @option $panels [jQuery, nil]- jQuery selector of all jquery panels
// @option $expanded [jQuery, nil] - the panel to initially expand
// @option useLocationHash [Boolean, nil](default false)
//   should the location hash correspond with the ID of the opened panel?
const Expander = function (options) {
  this.$panels = options.$panels

  if (typeof options.duration === 'undefined') {
    options.duration = 400
  }
  this.options = options

  this._hideAll()

  if (options.useLocationHash) {
    this._expandHashPanel(0, options.setInitialHash)
  }
  if (options.$expanded && !this.$expanded) {
    this._expand(options.$expanded, 0, options.setInitialHash)
  }

  this._bindListeners()
}

// PUBLIC CLASS

// take an array of jquery elements and
// create an array of dom elements
Expander.domArray = function (array) {
  if (arguments.length > 1) {
    array = Array.prototype.slice.call(arguments, 0)
  }

  const domArray = []
  $.each(array, (index, el) => {
    if (el instanceof $ || el instanceof Array) {
      domArray.push(Expander.domArray(el))
    } else {
      domArray.push(el)
    }
  })

  // flatten array
  return $.map(domArray, (n) => n)
}

// create one jquery object selecting all objects in the array
Expander.jqArray = function (array) {
  if (arguments.length > 1) {
    array = Array.prototype.slice.call(arguments, 0)
  }
  return $(Expander.domArray(array))
}

// PUBLIC INSTANCE

// expand a panel
Expander.prototype.expand = function ($panel, updateLocation) {
  this._expand($panel, undefined, updateLocation)
}

// expand a panel by the panel's selector
Expander.prototype.expandBySelector = function (selector) {
  this.expand(this._getPanelBySelector(selector))
}

// expand a panel determined by an element's data-target or href
Expander.prototype.expanderClick = function ($expander) {
  let dataTarget
  let selector

  if ((dataTarget = $expander.data('target') || '') !== '') {
    selector = dataTarget
  } else {
    selector = $expander.attr('href')
  }

  this.expandBySelector(selector)
}

// PRIVATE INSTANCE

// expand a panel, collapsing all other panels. (optional duration)
Expander.prototype._expand = function ($panel, duration, updateLocation) {
  const panelID = $panel.attr('id').toString()
  const hash = `#${panelID}`

  if (updateLocation !== false
      && this.options.useLocationHash
      && panelID !== ''
      && window.location.hash !== hash) {
    window.location.hash = hash
  }

  if (this.$expanded && this.$expanded.attr('id') === panelID) {
    return
  }

  duration = typeof duration === 'undefined' ? this.options.duration : duration

  if (duration > 0) {
    this.$expanded && this.$expanded.stop(true, false).slideUp(duration)
    $panel.stop(true, false).slideDown(duration)
  } else {
    this.$expanded && this.$expanded.hide()
    $panel.show()
  }

  this.$expanded = $panel
}

Expander.prototype._getPanelBySelector = function (selector) {
  try {
    return this.$panels.filter(selector)
  } catch (error) {
    return []
  }
}

Expander.prototype._expandHashPanel = function (duration, updateLocation) {
  const locationHash = window.location.hash
  const $hashPanel = this._getPanelBySelector(locationHash)

  if ($hashPanel.length > 0) {
    this._expand($hashPanel, duration, updateLocation)
    return $hashPanel
  }
}

Expander.prototype._hideAll = function () {
  this.$panels.hide()
}

Expander.prototype._bindListeners = function () {
  const self = this
  $(window).on('hashchange', () => {
    self._expandHashPanel(undefined, true)
  })

  self.$panels.find('.expander').on('click', function (event) {
    event.preventDefault()
    self.expanderClick($(this))
  })
}

export default Expander
