import $ from 'jquery'
import Expander from '../components/Expander'
import Form from '../components/Form'
import PopupUploader from '../files/PopupUploader'
import ready from '../utils/ready'

ready(() => {
  const $setupAttachMedia = $('#setup-attach-media.authorized')
  let $useExistingPanel
  let $nameNewMediaPanel
  let $existingMediasPanel
  let $uploadMethodsPanel
  let $panels
  let metadataForm

  // uploader popup
  let $browseHardDrive
  let popupUploader

  const setupPanels = function () {
    $useExistingPanel = $('#use-existing-container')
    $nameNewMediaPanel = $('#name-new-media-container')
    $existingMediasPanel = $('#existing-medias-container')
    $uploadMethodsPanel = $('#upload-methods-container')
    $panels = Expander.jqArray($useExistingPanel,
      $nameNewMediaPanel,
      $existingMediasPanel,
      $uploadMethodsPanel)

    /* eslint-disable no-new */
    new Expander({
      $panels,
      '$expanded': _getInitialPanel(),
    })
    /* eslint-enable no-new */
  }

  const setupAjaxForms = function () {
    metadataForm = new Form('#metadata-form', {
      namespace: 'metadata',
    })

    metadataForm.onSuccess(() => {
    // expander.expand($uploadMethodsPanel);
    // reload page instead so we can use the upload_key from the new media
      window.location.reload()
    })
  }

  const _getInitialPanel = function () {
  // TODO: decide if it's better to show the option panel,
  //       or default to "existing" when available
    const allowUseExistingPanel = false

    if ($setupAttachMedia.hasClass('has-media')) {
      return $uploadMethodsPanel
    } else if ($setupAttachMedia.hasClass('available-medias')) {
      if (allowUseExistingPanel) {
        return $useExistingPanel
      }

      return $existingMediasPanel
    }

    return $nameNewMediaPanel
  }

  if ($setupAttachMedia.length > 0) {
    $browseHardDrive = $('.file-upload #browse-hard-drive')

    popupUploader = new PopupUploader($('#setup-upload'), {
      modalID: 'uploader-opened-modal',
      modalTemplateID: 'uploader-opened-modal-template',
    })

    $browseHardDrive.on('click', (event) => {
      event.preventDefault()
      const url = $browseHardDrive.data('url')
      popupUploader.open(url)
    })

    setupPanels()
    setupAjaxForms()
  }
})
