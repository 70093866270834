import $ from 'jquery'
import Form from '../components/Form.js'
import SelectField from '../components/SelectField'
import helpers from '../ui/helpers.js'
import ready from '../utils/ready'
import 'bootstrap/js/tooltip' // eslint-disable-line sort-imports-es6-autofix/sort-imports-es6

let mediaForm
let releaseForm
let artistForm
let artistSelectionForm
let $artistSelect
let $songName
let expandContainer
let expandViewContainer
let slideToggle
let currentSongChanged
let currentArtistChanged

ready(() => {
  if ($('#media-metadata').length > 0) {
    mediaForm = new Form('#media-metadata', {
      namespace: 'metadata',
    })
    releaseForm = new Form('#release-metadata', {
      namespace: 'release',
    })
    artistForm = new Form('#artist-metadata', {
      namespace: 'artist',
    })
    artistSelectionForm = new Form('#artist-selection')
    $artistSelect = artistSelectionForm.$form.find('#media_artist_id')
    $songName = $('#song_name')

    slideToggle = function ($el, slideDown) {
      $el.stop(true, false)
      if (slideDown) {
        $el.slideDown()
      } else {
        $el.slideUp()
      }
      return $el
    }

    expandViewContainer = function ($container) {
      let $scrollTo

      if ($container && $container.length > 0) {
        slideToggle($container.find('.view-only-expanded-content'), true)
        slideToggle($container.find('.view-only-contracted-content'), false)

        $container.addClass('expanded')

        $scrollTo = $('.metadata-container:first')
        helpers.scroll_to($scrollTo, 400)
      }
    }

    expandContainer = function ($container) {
      let $scrollTo

      $container.prevAll().removeClass('hidden')
      $container.nextAll().addClass('hidden')
      $container.removeClass('hidden')

      currentSongChanged($('#metadata_song_name').val(), $('#metadata_featured_artist').val())

      const $expandedContainer = $('.metadata-container.expanded')

      slideToggle($expandedContainer.find('.contracted-content'), true)
      slideToggle($expandedContainer.find('.expanded-content'), false)

      $expandedContainer.removeClass('expanded')

      if ($container && $container.length > 0) {
        slideToggle($container.find('.expanded-content'), true)
        slideToggle($container.find('.contracted-content'), false)

        $container.addClass('expanded')

        $scrollTo = $container.prev()
        if ($scrollTo.length === 0) {
          $scrollTo = $('.metadata-container:first')
        }
        helpers.scroll_to($scrollTo, 400)
      }
    }

    currentArtistChanged = function (artistID, afterSave) {
      let artistFormAction
      let artistNameDisabled

      artistForm.removeErrors()

      !afterSave && artistForm.clearFields()

      if (artistID) {
        artistNameDisabled = true

        !afterSave && artistForm.start_submitting()

        $.getJSON(`/artists/${artistID}`, (data) => {
          artistForm.populate(data)
          !afterSave && artistForm.stop_submitting()
          $('#artist_name').prop('disabled', artistNameDisabled)
        })

        artistFormAction = `/artists/${artistID}/edit`

        $artistSelect.val(artistID.toString())
      } else {
        artistFormAction = '/artists/new'
        artistNameDisabled = false
      }

      $('#artist_name').prop('disabled', artistNameDisabled)

      artistForm.$form.attr('action', artistFormAction)
    }

    currentSongChanged = function (song, featuring) {
      song || (song = 'Untitled Song')
      featuring = featuring ? ` ft. ${featuring}` : ''
      $songName.text(song + featuring)
    }

    artistForm.onSuccess((artistData) => {
      const artistID = artistData.id
      const val = $artistSelect.val()
      const newArtist = (val ? val.toString() : '') !== artistID.toString()

      if (newArtist) {
        if ($artistSelect.find('option').length > 0) {
          $artistSelect.append(`<option value="${artistID}">${
            artistData.name}</option>`)
        }
        $artistSelect.val(artistID)
        currentArtistChanged(artistID, true)
        artistSelectionForm.submit()
      }
      expandContainer($('.metadata-container:has(#release-metadata)'))
    })

    $artistSelect.on('change', () => {
      const value = $artistSelect.val()

      currentArtistChanged(value)
      if (value) {
        artistSelectionForm.submit()
      }
    })

    $('.metadata-container').on('click', '.view-container-expander', (event) => {
      const $container = $(event.delegateTarget)

      event.preventDefault()

      expandViewContainer($container)
    })

    $('.metadata-container').on('click', '.container-expander', (event) => {
      const $container = $(event.delegateTarget)

      event.preventDefault()

      expandContainer($container)
    })

    releaseForm.onSuccess(() => {
      setTimeout(() => {
        window.location.href = $('#next-step').attr('href')
      }, 400)
    })

    $('.metadata-container').on('click', '.optional-content a', function (event) {
      const $this = $(this)
      const $toggler = $this.closest('.optional-content')
      const $container = $(event.delegateTarget)
      const $optionalFields = $('.form-field-optional')
      const wasExpanded = $container.hasClass('show-optional')
      let togglerText
      let togglerIcon

      event.preventDefault()

      if (wasExpanded) {
        slideToggle($optionalFields, false)
        togglerText = 'Show more optional fields'
        togglerIcon = $toggler.data('icon-hidden')
      } else {
        slideToggle($optionalFields, true)
        togglerText = 'Hide more optional fields'
        togglerIcon = $toggler.data('icon-visible')
      }

      $container.toggleClass('show-optional', !wasExpanded)
      $this.html(`<span class="fa ${togglerIcon}"></span> ${togglerText}`)
    })

    if (window.activeMetadata !== '' && window.activeMetadata !== 'media-metadata') {
      expandContainer($(`.metadata-container:has(#${window.activeMetadata})`))
    }

    // Custom value selects
    /* eslint-disable no-new */
    new SelectField(mediaForm.$form.find('#metadata_publisher'), {
      other_prompt: 'Enter the Publisher name:',
    })
    new SelectField(mediaForm.$form.find('#metadata_rights_society'), {
      other_prompt: 'Enter the Rights Society name:',
    })
  }
})
