/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */

// TODO: distribute release disabled
import $ from 'jquery'
import './create_metadata.js'
import './upload_file.js'
import './confirmation.js'

$('#upload-type-list').find('li')
  .click((event) => {
    const $target = $(event.target)
    let radio

    if (!$target.is('label') && !$target.is('input:radio')) {
      radio = $(event.delegateTarget).find('input:radio')
        .get(0)
      if (!radio.disabled) {
        radio.checked = true
      }
    }
  })
