import { some } from 'lodash-es'

// TODO: REMOVE CONSOLE LOGS AND REPLACE WITH LOGIC TO HANDLE WHEN APP IS INSTALLED

if (typeof navigator.getInstalledRelatedApps !== 'undefined') {
  navigator.getInstalledRelatedApps().then((relatedApps) => {
    const androidAppIsInstalled = some(relatedApps, (app) => app.id === 'com.vydia.app')

    // if (androidAppIsInstalled) {
    //   // var vydiaAndroidApp = relatedApps[0]
    //   // console.log(vydiaAndroidApp.platform);
    //   // console.log(vydiaAndroidApp.url);
    //   // console.log(vydiaAndroidApp.id);
    // } else {
    //   // console.warn("Android App Not Found.")
    // }
  })

  window.addEventListener('load', () => {
    navigator.serviceWorker.register('service-worker.js', { scope: './' })
      .then(() => {
        // console.log('registered service worker');
      })
      .catch(() => {
        // console.error(error);
      })

    window.addEventListener('beforeinstallprompt', () => {
      // console.log('beforeinstallprompt Event fired');
    })
  })
}
