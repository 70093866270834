const Validation = {}

// options: {:min, :max} or number
Validation.validateLength = function ($input, options) {
  const value = $input.val()
  const length = (value && value.length) || 0

  if (typeof options === 'number') {
    if (length !== options) return 'invalid'
  } else {
    if (length < options.min) return 'invalid'
    if (length > options.max) return 'invalid'
  }
  return undefined
}

export default Validation
